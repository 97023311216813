<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'

defineLayout({
  label: 'Music Making Waves',
})

useAtlantisHead()

useSeoMeta({
  title: 'Music Making Waves | Bahamas Events | Atlantis Paradise Island',
  ogTitle: 'Music Making Waves | Bahamas Events | Atlantis Paradise Island',
  description: 'Proceeds from the music making waves concert series support Atlantis Blue Project Foundation.',
  ogDescription: 'Proceeds from the music making waves concert series support Atlantis Blue Project Foundation.',
  ogImage: 'https://www.atlantisbahamas.com/storage/media/atlantis-property-shot-royal-hero-sky.jpg',
  ogUrl: 'https://www.atlantisbahamas.com/music-making-waves',
})

// Generated much of this at https://realfavicongenerator.net
useHead({
  htmlAttrs: {
    lang: 'en-US',
  },
  meta: [
    { name: 'msapplication-TileColor', content: '#000000' },
    { name: 'msapplication-config', content: '/favicons/atlantisbahamas/browserconfig.xml' },
    { name: 'theme-color', content: '#000000' },
  ],
  link: [
    { rel: 'stylesheet', href: 'https://use.typekit.net/mbc1jbt.css' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/atlantisbahamas/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/atlantisbahamas/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/atlantisbahamas/favicon-16x16.png' },
    { rel: 'manifest', href: '/favicons/atlantisbahamas/site.webmanifest' },
    { rel: 'mask-icon', href: '/favicons/atlantisbahamas/safari-pinned-tab.svg', color: '#5bbad5' },
    { rel: 'shortcut icon', href: '/favicons/atlantisbahamas/favicon.ico' },
  ],
  script: [
    { src: '//fast.wistia.com/assets/external/E-v1.js', async: true },
  ],
})

const route = useRoute()
const pageStore = usePageStore()
const isVoixTemplatizer = inject('isVoixTemplatizer', false)
const config = useRuntimeConfig()
const { data: mainMenu } = await useFetch(
    `${config.public.voixApiBaseUrl}/api/sites/${pageStore.currentPage?.site_id}/menus/Glue%20Main%20Navigation`,
)
const nochrome = route.query.nochrome
</script>

<template>
  <div id="app" class="relative min-h-screen mmw-theme">
    <div id="glue" class="text-zinc-700 bg-white" :class="{ nochrome }">
      <div class="relative z-40">
        <div id="portal-modal" />
      </div>

      <div class="flex flex-col items-center">
        <!-- TODO: Missing ta_track_num -->
        <MmwDesktopNavigation class="hidden xl:block" :menu="mainMenu" />
        <MmwMobileNavigation class="xl:hidden" :menu="[]" />

        <div class="big-container w-full overflow-hidden">
          <div
            class="relative z-0 min-h-[50vh]"
            :class="{
              'pt-32': isVoixTemplatizer,
            }"
          >
            <slot />
          </div>
        </div>
      </div>
      <div v-if="!nochrome" class="relative z-0">
        <MmwFooter />
      </div>
    </div>
    <GlobalUIPopupForm
      form="popup-mmw"
      image="https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/styled/c9be9e713ce0f3111c21c2babcfa165c"
      title="SIGN UP FOR SPECIAL OFFERS AND MORE"
      terms="You agree to receive marketing emails from us. You can opt out at any time"
      travel-agent="Are you a travel agent?"
    />
  </div>
</template>

<style lang="scss">
@import '~/assets/sass/mmw.scss';
</style>
